import React, { useImperativeHandle, useState } from "react"
import findIndex from 'lodash/findIndex';
import { Button } from "antd"
import { ChevronLeftIcon } from "components/common/SVGIcons"

import { ArtistDetailStyles } from "./styles"
import BottomOverlay from "../Artists/BottomOverlay"
import DetailsInfo from "./DetailsInfo"
import { ARTWORKS } from "data/artworks"

const totalArtworks = ARTWORKS.length

function ArtistDetail(props, ref) {
  const [visible, setVisible] = useState(false)
  const [currentIdx, setCurrentIdx] = useState(0)

  const onCancel = () => {
    setVisible(false)
  }

  useImperativeHandle(
    ref,
    () => ({
      open: id => {
        setVisible(true)
        setCurrentIdx(findIndex(ARTWORKS, ["id", id]))
      },
    }),
    []
  )

  return (
    <ArtistDetailStyles
      placement="right"
      onClose={onCancel}
      visible={visible}
      closable={false}
      width='80vw'
      destroyOnClose
    >
      {visible && (
        <Button
          onClick={onCancel}
          shape="circle"
          className="btn-gray menu-button toggle-drawer"
          icon={<ChevronLeftIcon />}
        />
      )}
      <DetailsInfo
        item={ARTWORKS[currentIdx]}
        currentIdx={currentIdx}
        setCurrentIdx={setCurrentIdx}
        totalArtworks={totalArtworks}
      />

      <BottomOverlay />
    </ArtistDetailStyles>
  )
}

export default React.forwardRef(ArtistDetail)
