import styled from "styled-components"

export const ProjectItemStyles = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  padding-bottom: 10px;
  cursor: pointer;
  height: 100%;

  .dot-16 {
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }

  .bg-secondary {
    background-color: ${({ theme }) => theme.background.c4};
  }

  .project-item {
    &__name {
      line-height: 32px;
      margin-top: 15px;
      h3 {
        font-size: 22px;
      }
    }

    &__artist-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.text.text_4f};
    }
    &__artist-country {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.text_77};
      text-transform: uppercase;
    }
  }
`

export const BottomOverlayStyles = styled.div`
  position: sticky;
  bottom: -1px;
  left: 0;
  right: -1px;
  height: 120px;
  background: linear-gradient(transparent, #fff);
`;
