import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
// import { StaticImage } from "gatsby-plugin-image"
import Space from "antd/lib/space"
import Image from "antd/lib/image"
import Button from "antd/lib/button"
import Slider from "antd/lib/slider"
import Row from "antd/lib/row"
import Col from "antd/lib/col"

import Slide from "components/common/Slide"
import { DEFAULT_THUMBNAIL } from "configs/constants"
import ReactPlayer from "react-player/lazy"

const DetailsInfo = ({ item, currentIdx, totalArtworks, setCurrentIdx }) => {
  const headerRef = useRef()

  const onPrev = () => {
    setCurrentIdx(currentIdx - 1)
  }

  const onNext = () => {
    setCurrentIdx(currentIdx + 1)
  }

  const handleScroll = () => {
    const elem = document.querySelector(".ant-drawer-body")
    if (elem && headerRef.current) {
      // console.log(elem.scrollTop, "elem.scrollTop")
      if (elem.scrollTop < 291) {
        headerRef.current.style.top = `${-elem.scrollTop}px`
      } else {
        headerRef.current.style.top = "-290px"
      }
    }
  }

  useEffect(() => {
    const debounceHandleScroll = debounce(handleScroll, 200)
    const elem = document.querySelector(".ant-drawer-body")

    if (elem) {
      elem.addEventListener("scroll", debounceHandleScroll, true)
    }

    return () => {
      if (elem) {
        elem.removeEventListener("scroll", debounceHandleScroll)
      }
    }
  }, [])

  return (
    <div className="project-detail-info">
      <div ref={headerRef} id="projectDetailHeader" className="project__header">
        <div className="project-slide">
          {item?.medias?.length ? (
            <Slide variableWidth={false}>
              {item.medias.map((media, idx) => (
                <div key={String(idx)}>
                  {media.type === "video" ? (
                    <ReactPlayer
                      url={media.url}
                      className="react-player"
                      width="100%"
                      height="450px"
                      controls
                    />
                  ) : (
                    <Image preview src={media.url} alt="The White Distance" />
                  )}
                </div>
              ))}
            </Slide>
          ) : (
            <Image
              className="default-thumbnail"
              preview={false}
              src={DEFAULT_THUMBNAIL}
              alt="A artist project"
            />
          )}
        </div>

        <div className="project-pagination">
          <div className="project-pagination--left">{`${
            currentIdx + 1
          }/${totalArtworks}`}</div>

          <div className="project-pagination--right">
            <Button size="small" onClick={onPrev} disabled={!currentIdx}>
              Prev
            </Button>
            <Slider
              range
              min={1}
              max={totalArtworks}
              value={[currentIdx + 1, currentIdx + 5]}
            />
            <Button
              size="small"
              onClick={onNext}
              disabled={currentIdx + 1 >= totalArtworks}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <div className="project-main-content">
        <Space className="project-owner" direction="vertical">
          {item?.artists?.map((artist, idx) => (
            <div
              key={String(idx)}
              className="project-owner-item"
            >{`${artist.fullName} (${artist.country})`}</div>
          ))}
        </Space>
        <div className="project-title">
          <h2 style={{ marginBottom: 5 }}>{item?.displayName?.vi}</h2>
          <h2>{item?.displayName?.en}</h2>
        </div>
        {/* <div
            className="project-content"
            dangerouslySetInnerHTML={{ __html: item?.description }}
          /> */}
        <div className="project-content-wrapper">
          {item?.extraDescription && (
            <div dangerouslySetInnerHTML={{ __html: item?.extraDescription }} />
          )}
          <Row gutter={[20, 20]}>
            <Col md={12} sm={24} xs={24}>
              <div
                className="project-content-item"
                dangerouslySetInnerHTML={{
                  __html: item?.displayDescription?.vi,
                }}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <div
                className="project-content-item"
                dangerouslySetInnerHTML={{
                  __html: item?.displayDescription?.en,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

DetailsInfo.propTypes = {
  item: PropTypes.object,
  currentIdx: PropTypes.number,
  totalArtworks: PropTypes.number,
  setCurrentIdx: PropTypes.func,
}

export default DetailsInfo
