import styled from "styled-components"
import { Drawer } from "antd"

export const ArtistDetailStyles = styled(Drawer)`
  .ant-drawer-content-wrapper {
    max-width: 94vw;
  }
  .ant-drawer-content {
    overflow: visible;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .gatsby-image-wrappergatsby-image-wrapper-constrained {
    max-width: 100%;
    width: 100%;
  }

  .toggle-drawer {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 5;
    transform: translateX(-50%);
  }

  /* div[data-vimeo-initialized="true"] > iframe {
    background-color: #000;
  } */

  .project__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: all linear 0.4s;
    z-index: 2;

    .project-slide {
      height: 450px;
      /* background-color: #fff; */
      background-color: #000;
    }
    /* .slide-wrapper,
    .slide-wrapper .container-slide,
    .ant-carousel,
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-track > div > div, .slick-track > div > div > div {
      height: 100%;
    } */
    .ant-image {
      width: 100%;
    }
    img {
      height: 100%;
      user-select: none;
      object-fit: contain;
      /* transition: all linear 0.5s; */
      /* height: 100%; */
      height: 450px;
    }
    .default-thumbnail {
      object-fit: cover !important;
    }
  }

  .project {
    &-main-content,
    &-pagination {
      padding: 20px 10vw;
    }
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #fff;

      &--right {
        display: flex;
        min-width: 250px;
        align-items: center;
        margin-left: 20px;

        .ant-slider {
          flex-grow: 1;
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .ant-slider-handle {
            display: none;
          }

          .ant-slider-rail,
          .ant-slider-track,
          .ant-slider-step {
            height: 1px;
          }
        }

        .ant-btn {
          background: transparent !important;
          border: none !important;
        }
      }
    }

    &-owner {
      font-size: 18px;
      line-height: 22px;
    }

    &-title {
      margin-top: 10px;
      h2 {
        font-size: 30px;
      }
      /* max-width: 600px; */
    }

    &-content {
      margin-top: 30px;
      column-count: 2;
      column-gap: 20px;
      /* max-width: 450px; */
      column-fill: balance;
      p {
        white-space: pre-line;
        /* -webkit-column-break-inside: avoid;
        -moz-column-break-inside: avoid;
        break-inside: avoid; */
      }
    }
    &-content-wrapper {
      margin-top: 30px;
    }
  }

  .title-section {
    font-size: 18px;
    font-weight: bold;
  }

  @media only screen and (max-width: 500px) {
    .ant-drawer-content-wrapper {
      width: 92vw !important;
    }
    .project {
      &-title {
        font-size: 24px;
        line-height: 30px;
      }
      &-content {
        column-count: 1 !important;
      }
      &-main-content,
      &-pagination {
        padding: 20px 20px;
      }
    }
  }
  /* .fixed-header {
    img {
      height: 160px !important;
    }
  } */
`
