import styled from 'styled-components';

export const SlideStyles = styled.div`
  .container-slide {
    position: relative;
  }

  .slick-arrow-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    cursor: pointer;
    user-select: none;

    border: none !important;
    background: rgb(0, 0, 0, 0.2) !important;
    .anticon {
      color: ${({ theme }) => theme.background.white} !important;
    }
  }

  .slick-prev {
    &__custom {
      left: 10px;
    }
  }
  .slick-next {
    &__custom {
      right: 10px;
    }
  }

  .ant-carousel .slick-list .slick-slide {
    pointer-events: all;
  }
`;
